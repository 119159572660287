/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { useEffect, useState } from 'react';
// reactstrap components
import ReactPaginate from 'react-paginate';
import { useToasts } from 'react-toast-notifications';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  ModalHeader,
} from 'reactstrap';

import FullPageLoader from 'components/FullPageLoader/fullpageloader';
import NoRecordFound from 'components/NoRecordFound/NoRecordFound';
// core components
import { getPrivileges, getSortCls } from 'services/util';

import { btnCss, iconCss } from '../../appConfig';
import { postMethod } from '../../services/httpServices';

import AddScheme from './add-scheme';
import EditScheme from './edit-scheme';

const sortIS = {
  sortName: 0,
};

const CardSchemeList = () => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEditModal] = useState(false);
  const [editObj, setEditObj] = useState(null);

  // const [view, setView] = useState(false);
  // const [merchantObj, setMerchantObj] = useState(null);
  // const [editObj, setEditObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [pagesCount, setPageCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalRec, setTotalRec] = useState(0);
  const [sort, setSort] = useState(sortIS);
  const [refresh, setRefresh] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const { addToast } = useToasts();
  const privileges = getPrivileges('merchant');

  useEffect(() => {
    if (!refresh && isMounted) {
      if ((searchText && searchText.length >= 3) || searchText.length == 0) {
        fetchNFC();
      }
    }
  }, [searchText]);

  useEffect(() => {
    fetchNFC();
  }, [currentPage]);

  useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchNFC();
      }
    }
  }, [sort]);

  useEffect(() => {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchNFC();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setOpenEditModal(false);
    fetchNFC();
  };

  const notifyFailed = text => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  };
  const openDelete = mmo => {
    setDeleteObj(mmo);
    setOpenDelModal(true);
  };
  const fetchNFC = async () => {
    let errorMsg = '';
    setLoading(true);
    const data = {
      PageNumber: Number(currentPage + 1),
      PerPage: pageSize,
    };

    if (searchText && searchText.length >= 3) {
      data.SearchText = searchText;
      // data['PageNumber'] = 0;
    }
    if (sort.sortName == '1') {
      data.SortingOn = 'cardSchemeName';
      data.IsDescending = false;
    } else if (sort.sortName == '2') {
      data.SortingOn = 'cardSchemeName';
      data.IsDescending = true;
    }
    postMethod('/api/CardSchemes/Search', data, 'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (res && res.data) {
          if (res.data.responseCode === '200' && res.data.result) {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            setFilteredList(res.data.result.list);
            const pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === '400') {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      })
      .catch(() => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Something went wrong!, Please call 3737');
      });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    // fetchSubs();
  };

  const getSearch = val => {
    setSearchText(val);
  };

  const closeDeleteModal = () => {
    setDeleteObj(null);
    setOpenDelModal(false);
  };

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  };
  const getBlockText = () => {
    let str = 'block';
    if (deleteObj.isBlock) {
      str = 'unblock';
    }

    return `Do you want to ${str} ${deleteObj?.cardSchemeName}?`;
  };
  const notifySuccess = text => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  };
  const deleteNFC = () => {
    let errorMsg = '';
    setModalLoading(true);
    setLoading(true);
    postMethod(
      `/api/CardSchemes/SoftDeleteOrBlock/${deleteObj.id}?isDelete=${
        deleteObj.isDelete
      }&isBlock=${!deleteObj.isBlock}`,
      {},
      'post'
    )
      .then(res => {
        setModalLoading(false);
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === '200') {
            const text = deleteObj.isBlock
              ? 'Card Scheme unblocked successfully'
              : 'Card Scheme blocked successfully';
            notifySuccess(text);
            fetchNFC();
            closeDeleteModal();
          } else if (res.data.responseCode === '400') {
            // Error while saving
            errorMsg = res.data.responseMessage || 'Error while saving';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in saving';
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      })
      .catch(() => {
        setModalLoading(false);
        setLoading(false);
        errorMsg = 'Something went wrong!, Please call 3737';
        notifyFailed(errorMsg);
      });
  };

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == '0') {
      newValue = '1';
    } else if (fieldValue == '1') {
      newValue = '2';
    } else if (fieldValue == '2') {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  };

  return (
    <>
      {/* Page content */}
      {/* Table */}
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0 d-flex p-0 justify-content-between align-items-center">
              {/* <h3 className="mb-0">Card tables</h3> */}

              <Form
                className="form-inline mr-3 d-md-flex ml-lg-auto"
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <FormGroup className="mb-0 navbar-search ">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="search-cls"
                      placeholder="Search"
                      type="text"
                      value={searchText}
                      onChange={e => getSearch(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                &nbsp;&nbsp;
                {privileges.add && (
                  <Button className="my-4" color="primary" onClick={openModal}>
                    Add Scheme
                  </Button>
                )}
                <Button
                  color="primary"
                  onClick={reloadList}
                  size="sm"
                  style={btnCss}
                  data-toggle="tooltip"
                  title="Refresh"
                >
                  <i className="fas fa-redo fa-lg" style={iconCss} />
                </Button>
              </Form>
            </CardHeader>

            <Modal
              isOpen={open}
              // fade={false}
              style={{ width: '70vw', maxWidth: '70vw' }}
            >
              <ModalBody style={{ padding: 0 }}>
                <AddScheme closeModal={closeModal} />
              </ModalBody>
            </Modal>

            <Modal
              isOpen={openEdit}
              // fade={false}
              style={{ width: '70vw', maxWidth: '70vw' }}
            >
              <ModalBody style={{ padding: 0 }}>
                <EditScheme closeModal={closeModal} values={editObj} />
              </ModalBody>
            </Modal>

            <Modal isOpen={openDelModal} className="modal-sm">
              <ModalHeader className="bg-white border-0">
                <h3 className="mb-0">
                  {deleteObj && deleteObj.isBlock ? 'Unblock Scheme' : 'Block Scheme'}
                </h3>
              </ModalHeader>

              <ModalBody>
                <div>
                  {deleteObj && deleteObj.cardSchemeName ? (
                    <div>
                      <p className="text-left">{getBlockText()}</p>
                      <div className="text-right">
                        <Button color="primary" onClick={deleteNFC} disabled={modalLoading}>
                          Yes {modalLoading && <i className="fa fa-spinner fa-spin" />}
                        </Button>{' '}
                        <Button
                          color="secondary"
                          onClick={closeDeleteModal}
                          disabled={modalLoading}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
              </ModalBody>
            </Modal>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" onClick={() => sortHandle(sort.sortName, 'sortName')}>
                    Card Scheme Name
                    <button style={btnCss}>
                      <i
                        className={getSortCls(sort.sortName)}
                        onClick={() => sortHandle(sort.sortName, 'sortName')}
                      />
                    </button>
                  </th>
                  <th scope="col">Issuer Name</th>
                  <th scope="col">Issuer Phone</th>
                  <th scope="col">Account Status</th>
                  <th scope="col">Card Application</th>
                  <th scope="col">Pin rule</th>
                  <th scope="col">Group Name</th>
                  <th scope="col">created by</th>
                  <th scope="col">created date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredList.length > 0 &&
                  filteredList.map((mmo, i) => {
                    return (
                      <tr key={i}>
                        <th>{mmo.cardSchemeName ?? 'NA'}</th>
                        <th>{mmo.issuerName ?? '-'}</th>
                        <td>{mmo.issuerPhone ?? '-'}</td>
                        <td className="text-center" title={mmo.accountStatus}>
                          <i
                            className={`fas ${
                              mmo.accountStatus === 'Standalone'
                                ? 'fa-circle'
                                : mmo.accountStatus === 'Linked'
                                ? 'fa-link'
                                : ''
                            } ${
                              mmo.accountStatus === 'Linked'
                                ? 'text-success'
                                : mmo.accountStatus === 'Standalone'
                                ? 'text-danger'
                                : ''
                            }`}
                          />
                        </td>
                        <td>{mmo.cardApplication}</td>
                        <td>{mmo.pinRule}</td>
                        <td>{mmo.groupName ?? '-'}</td>
                        <td>{mmo.createdBy}</td>
                        <td>{new Date(mmo.createdDate).toLocaleDateString()}</td>

                        <td>
                          {privileges.edit && (
                            <Button
                              color="primary"
                              onClick={() => {
                                setEditObj(mmo);
                                setOpenEditModal(true);
                              }}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Edit"
                            >
                              <i className="fas fa-edit fa-lg" style={iconCss} />
                            </Button>
                          )}
                          {!mmo.isBlock ? (
                            <Button
                              color="primary"
                              onClick={() => openDelete(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Block"
                            >
                              <i className="fa fa-eye-slash fa-lg" style={iconCss} />
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => openDelete(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              title="Unblock"
                            >
                              <i className="fa fa-eye-slash fa-lg" style={{ color: '#FF0000' }} />
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              {loading && <FullPageLoader label="Fetching..." />}
              {!loading && !filteredList.length && <NoRecordFound />}
            </Table>
            <CardFooter className="py-4">
              {filteredList.length > 0 && (
                <Row>
                  <Col lg="6">
                    <div>
                      <small>
                        <strong>Total Records : {totalRec}</strong>
                      </small>
                    </div>
                  </Col>

                  <Col lg="6">
                    <nav aria-label="...">
                      <div className="float-right">
                        <ReactPaginate
                          previousLabel="prev"
                          nextLabel="next"
                          breakLabel="..."
                          breakClassName="break-me"
                          pageCount={pagesCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName="paginationV2"
                          subContainerClassName="pages paginationV2"
                          activeClassName="active"
                          forcePage={currentPage}
                        />
                      </div>
                    </nav>
                  </Col>
                </Row>
              )}
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default CardSchemeList;
