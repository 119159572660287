import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ViewMasterAgent = (props) => {
  const [values] = React.useState(props);
  const mobWithCC = values.mobileCode + values.mobileNumber;
  const bankMobWithCC = values.bbanLinkMobileCode + values.bbanLinkMobileNumber;

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Master Agent Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Profile Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-fname"
                            placeholder="First Name"
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lname"
                            placeholder="Last Name"
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={"sl"}
                            value={mobWithCC}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="email"
                            name="email"
                            value={values.email}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  {/* <h6 className="heading-small text-muted mb-4">
                    Mobile Money Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            htmlFor="input-mobilemoney"
                            className="form-control-label"
                          >
                            Mobile Money
                          </label>
                          <Input
                            type="select"
                            id="input-mobilemoney"
                            className="form-control-alternative"
                            name="mobilemoney"
                            value={values.mmoId}
                            disabled={true}
                          >
                            <option key="" value="">
                              -----Select MMO-----
                            </option>
                            {values.mobileMoneyList &&
                              values.mobileMoneyList.map((c) => (
                                <option key={c.key} value={c.key}>
                                  {c.value}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mmaccno"
                          >
                            MM Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-mmaccno"
                            placeholder="Acount Number"
                            type="text"
                            name="mobileMoneyAccNo"
                            value={values.mmoAccountNumber}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" /> */}
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Bank Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-bank"
                            className="form-control-label"
                          >
                            FSP
                          </label>
                          <Input
                            type="select"
                            id="input-bank"
                            className="form-control-alternative"
                            name="mobilemoney"
                            value={values.bankProfileId}
                            disabled={true}
                          >
                            {values.bankList &&
                              values.bankList.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {c.fspName}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bban"
                          >
                            BBAN Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bban"
                            placeholder="BBAN Number"
                            type="text"
                            name="bban"
                            value={values.bban || ""}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bank-accno"
                          >
                            Bank Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bank-accno"
                            placeholder="Bank Account Number"
                            type="text"
                            name="bankAccountNumber"
                            value={values.bankAccountNumber}
                            disabled
                          />
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Bank Linked Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={"sl"}
                            value={bankMobWithCC}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewMasterAgent;
