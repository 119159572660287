import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  Container,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";
import { checkSpace, getMethod } from "services/httpServices";

import Header from "components/Headers/Header.js";
import PreviewSubscriber from "../preview-subscriber";
import {
  AUTH_MODE,
  AMOUNT_HINT,
  PIN_HINT,
  PASSWORD_HINT,
  MOBILE_ONLY_COUNTRY,
} from "appConfig";
import { checkBankAccNo, checkName, checkBbanValidation } from "services/util";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MODES = AUTH_MODE;

const initialValue = {
  firstName: "",
  lastName: "",
  mobileCode: "",
  phone: "",
  mobWithoutCC: "",
  email: "",
  mobileMoney: "",
  mobileMoneyAccNo: "",
  bank: "",
  bban: "",
  bankLinkedMobileCode: 0,
  bankLinkedPhone: 0,
  bankMobWithoutCC: 0,
  password: "",
  oneFAAmt: "50000",
  authMode: "1FA",
  pin: "",
  registerType: "master_agent", //to check submit user type in preview subscriber modal
  subscriberType: "Individual",
  organisationName: "",
  bankAccNo: "",
};

const navItemCls = {
  minWidth: "120px",
  textAlign: "center",
};

const cardHeaderCls = {
  backgroundColor: "#6490CA",
};

const HINT = AMOUNT_HINT;

const AddMasterAgent = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [values, setValues] = React.useState(initialValue);
  const [showPswd, setShowPswd] = React.useState(false);
  const [showPin, setShowPin] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [, setDisableTab2] = React.useState(true);
  const [disableTab3, setDisableTab3] = React.useState(true);
  const [banks, setBanks] = React.useState(null);
  const [mobileMoney, setMMO] = React.useState(null);
  const { addToast } = useToasts();

  React.useEffect(() => {
    fetchBank();
    fetchMMO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;

    if (checkSpace(value)) {
      if (name === "phone" || name === "bankLinkedPhone") {
        if (checkMobValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "firstName") {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "lastName") {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "organisationName") {
        if (checkName(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "bban") {
        if (checkBbanValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "mobileMoneyAccNo") {
        if (checkMobileMoneyAccNo(value)) {
          setValues({ ...values, [name]: value });
        }
        // } else if (name === 'password') {
        //   if (pswdValidation(value)) {
        //     setValues({ ...values, [name]: value });
        //   }
      } else if (name === "pin") {
        if (pinValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "oneFAAmt") {
        if (checkDigitOnly(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === "bankAccNo") {
        if (checkBankAccNo(value)) {
          setValues({ ...values, [name]: value });
        }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values,
        phone: value,
        mobileCode: data.dialCode,
        mobWithoutCC: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const closeModal = () => {
    setOpen(false);
    setValues({ ...initialValue });
    setActiveTab("1");
    setDisableTab2(true);
    setDisableTab3(true);
  };

  const reviewModal = () => {
    setOpen(false);
  };

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkMobileMoneyAccNo = (value) => {
    if (value.length <= 11) {
      return true;
    } else {
      return false;
    }
  };

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkProfileTab = () => {
    let error = {};
    let valid = true;
    if (!values.subscriberType) {
      error.subscriberType = "Please select subscriber type";
      valid = false;
    }

    if (!values.firstName) {
      error.firstName = "First Name is Required";
      valid = false;
    }
    if (!values.lastName) {
      error.lastName = "Last Name is Required";
      valid = false;
    }
    if (!values.mobileCode) {
      error.mobileCode = "Mobile Code is Required";
      valid = false;
    }
    if (!values.phone) {
      error.phone = "Phone Number is Required";
      valid = false;
    } else if (!values.mobWithoutCC) {
      error.phone = "Phone Number is Required";
      valid = false;
    }
    if (!values.password) {
      error.password = "Password is Required";
      valid = false;
    } else if (values.password.length < 6) {
      error.password = "Password should have atleast 6 characters";
      valid = false;
    }

    if (!values.pin) {
      error.pin = "PIN is Required";
      valid = false;
    } else if (values.pin.length !== 4) {
      error.pin = "PIN should have 4 characters";
      valid = false;
    }
    if (values.email) {
      if (!checkEmail(values.email)) {
        error.email = "Please enter valid email";
        valid = false;
      }
    }
    setErrors(error);
    return valid;
  };

  const checkEmail = (email) => {
    let emailRegEx = /\S+@\S+\.\S+/;
    if (emailRegEx.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const validateProfile = () => {
    if (checkProfileTab()) {
      toggle("3");
      setDisableTab2(false);
      setValues({
        ...values,
        bankAccNo: values.phone,
        bankLinkedPhone: 0,
      });
    } else {
      setDisableTab2(true);
      setDisableTab3(true);
    }
  };

  const checkBank = () => {
    let error = {};
    let valid = true;
    if (!values.bank) {
      error.bank = "Bank is Required";
      valid = false;
    }

    if (!values.authMode) {
      error.authMode = "Please select authentication mode";
      valid = false;
    }

    if (values.authMode === "1FA" && values.oneFAAmt === "") {
      error.oneFAAmt = "Please enter amount";
      valid = false;
    }

    if (!values.bankAccNo) {
      error.bankAccNo = "Please enter bank account number";
      valid = false;
    } else if (values.bankAccNo.length < 8 || values.bankAccNo.length > 18) {
      error.bankAccNo = "Please enter 8 to 18 digit Bank Account Number";
      valid = false;
    }
    setErrors(error);
    return valid;
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const validateBank = () => {
    if (checkBank()) {
      setOpen(true);
    }
  };

  const fetchBank = async () => {
    let errorMsg = "";
    getMethod("/api/FSP/GetAll", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setBanks(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchMMO = async () => {
    let errorMsg = "";
    getMethod("/api/MobileMoney/ddllist", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setMMO(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const checkDigitOnly = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Modal isOpen={open} style={{ width: "70vw", maxWidth: "70vw" }}>
          <ModalBody style={{ padding: 0 }}>
            <PreviewSubscriber
              modalName="Master Agent Information"
              closeModal={closeModal}
              reviewModal={reviewModal}
              {...values}
              bankList={banks}
              mobileMoneyList={mobileMoney}
            />
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                  style={{ padding: "10px" }}
                >
                  Profile
                </NavLink>
              </NavItem>

              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                  style={{ padding: "10px" }}
                  disabled={disableTab3}
                >
                  Bank
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="card-border shadow">
                  <Card>
                    <CardHeader style={cardHeaderCls}>
                      <Row className="align-items-center">
                        <Col lg="12">
                          <h3 className="mb-0 text-center text-white">
                            Profile Info
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div>
                        {values.subscriberType && (
                          <div>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-fname"
                                  >
                                    First Name
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-fname"
                                    placeholder="First Name"
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleInputChange}
                                  />
                                  {errors.firstName && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.firstName}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-lname"
                                  >
                                    Last Name
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-lname"
                                    placeholder="Last Name"
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleInputChange}
                                  />
                                  {errors.lastName && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.lastName}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                  >
                                    Email
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-email"
                                    placeholder="jesse@example.com"
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                  />
                                  {errors.email && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.email}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-pswd"
                                  >
                                    PIN
                                  </label>
                                  <InputGroup className="input-group-alternative">
                                    <Input
                                      className="form-control-alternative"
                                      id="input-pswd"
                                      type={showPin ? "password" : "text"}
                                      name="pin"
                                      placeholder="Enter 4-digit Pin"
                                      value={values.pin}
                                      onChange={handleInputChange}
                                      autoComplete="off"
                                    />
                                    {!showPin && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye-slash"
                                            onClick={() => setShowPin(true)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}

                                    {showPin && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye"
                                            onClick={() => setShowPin(false)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}
                                  </InputGroup>
                                  *{" "}
                                  <small>
                                    <i>{PIN_HINT}</i>
                                  </small>
                                  {errors.pin && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.pin}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-pswd"
                                  >
                                    Phone Number
                                  </label>
                                  <PhoneInput
                                    className="form-control-alternative"
                                    country={"sl"}
                                    value={values.phone}
                                    // onlyCountries={MOBILE_ONLY_COUNTRY}
                                    countryCodeEditable={false}
                                    onChange={phoneChange}
                                  />
                                  {errors.phone && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.phone}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-pswd"
                                  >
                                    Password
                                  </label>
                                  <InputGroup className="input-group-alternative">
                                    <Input
                                      className="form-control-alternative"
                                      id="input-pswd"
                                      type={showPswd ? "password" : "text"}
                                      name="password"
                                      value={values.password}
                                      onChange={handleInputChange}
                                      autoComplete="off"
                                    />
                                    {!showPswd && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye-slash"
                                            onClick={() => setShowPswd(true)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}

                                    {showPswd && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye"
                                            onClick={() => setShowPswd(false)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}
                                  </InputGroup>
                                  *{" "}
                                  <small>
                                    <i>{PASSWORD_HINT}</i>
                                  </small>
                                  {errors.password && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.password}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg="12" >
                                <FormGroup>
                                  <Button
                                    className="btn float-right mt-4"
                                    color="primary"
                                    type="submit"
                                    onClick={validateProfile}
                                  >
                                    Save and Next
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </TabPane>

              <TabPane tabId="3">
                <div className="card-border">
                  <Card>
                    <CardHeader style={cardHeaderCls}>
                      <Row className="align-items-center">
                        <Col lg="12">
                          <h3 className="mb-0 text-center text-white">
                            Bank Info
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              htmlFor="input-bank"
                              className="form-control-label"
                            >
                              FSP
                            </label>
                            <Input
                              type="select"
                              id="input-bank"
                              className="form-control-alternative"
                              name="bank"
                              value={values.bank}
                              onChange={handleInputChange}
                            >
                              <option key="" value="">
                                ------Select FSP------
                              </option>
                              {banks &&
                                banks.map((c) => (
                                  <option key={c.id} value={c.id}>
                                    {c.fspName}
                                  </option>
                                ))}
                            </Input>
                            {errors.bank && (
                              <div className="text-left text-danger">
                                <small>* {errors.bank}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bank-accno"
                            >
                              Bank Account Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-bank-accno"
                              placeholder="Bank Account Number"
                              type="text"
                              name="bankAccNo"
                              value={values.bankAccNo}
                              onChange={handleInputChange}
                            />
                            {errors.bankAccNo && (
                              <div className="text-left text-danger">
                                <small>* {errors.bankAccNo}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              htmlFor="input-authmode"
                              className="form-control-label"
                            >
                              Authentication Mode
                            </label>
                            <Input
                              type="select"
                              id="input-authmode"
                              className="form-control-alternative"
                              name="authMode"
                              value={values.authMode}
                              onChange={handleInputChange}
                              disabled
                            >
                              <option key="">----Select Mode-----</option>
                              {MODES.map((c) => (
                                <option key={c.key}>{c.value}</option>
                              ))}
                            </Input>
                            {errors.authMode && (
                              <div className="text-left text-danger">
                                <small>* {errors.authMode}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        {values.authMode === "1FA" && (
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-1FA"
                              >
                                Enter amount for using 1FA
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-1FA"
                                placeholder="Amount"
                                type="text"
                                name="oneFAAmt"
                                value={values.oneFAAmt}
                                onChange={handleInputChange}
                                disabled
                              />

                              <div className="text-left">
                                <i>
                                  <small>* {HINT}</small>
                                </i>
                              </div>
                              {errors.oneFAAmt && (
                                <div className="text-left text-danger">
                                  <small>* {errors.oneFAAmt}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Button
                            className="btn float-right"
                            color="primary"
                            type="submit"
                            onClick={validateBank}
                          >
                            Save and Review
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AddMasterAgent;
