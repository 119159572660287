import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import { checkSpace, getMethod, postMethod } from "../../services/httpServices";

const initialValue = {
  feeId: 0,
  feeType: "",
  feeCalculateMethod: 0,
  feeName: "",
  feeValue: 0,
};

const AddFspTransactionFees = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [methods, setMethods] = React.useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    fetchMethods();
  }, []);

  function fetchMethods() {
    setLoading(true);
    getMethod("/api/FSP/Profile/FeesMethod/GetAll")
      .then((res) => {
        if (res.data?.result?.length) {
          setMethods(res.data.result);
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }
  const changeState = (obj) => {
    setValues(obj);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeModal = () => {
    props.closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.feeType?.trim()) {
      errors.feeType = "Fee Name is required";
    }
    if (!String(inputs.feeCalculateMethod)) {
      errors.feeCalculateMethod = "Calculation method  is required";
    }
    if (!String(inputs.feeAmount)) {
      errors.feeAmount = "Fee Amount is required";
    }

    return errors;
  };

  /**======================== API Calls ==========================*/

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      const payload = {
        ...values,
        feeType: values.feeType?.trim(),
        feeName: methods.find(
          (i) => i.methodId === Number(values.feeCalculateMethod)
        )?.methodName,
        feeCalculateMethod: Number(values.feeCalculateMethod),
        feeValue: Number(values.feeValue),
      };
      let errorMsg = "";
      setLoading(true);
      postMethod("/api/FSP/Profile/Fees/Save", payload, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfully");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add Transaction Fee</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-feeType"
                        >
                          Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-feeType"
                          placeholder="Name"
                          type="text"
                          name="feeType"
                          required
                          value={values.feeType}
                          onChange={handleInputChange}
                        />
                        {errors.feeType && (
                          <div className="text-left text-danger">
                            <small>* {errors.feeType}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-feeCalculateMethod"
                        >
                          Calculation Method
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-feeCalculateMethod"
                          placeholder="Calculation Method"
                          type="select"
                          name="feeCalculateMethod"
                          required
                          value={values.feeCalculateMethod}
                          onChange={handleInputChange}
                        >
                          <option key="" value="">
                            -----Select Calculation Method-------
                          </option>
                          {methods.map((item) => (
                            <option value={item.methodId} key={item?.methodId}>
                              {item?.methodName}
                            </option>
                          ))}
                        </Input>
                        {errors.feeCalculateMethod && (
                          <div className="text-left text-danger">
                            <small>* {errors.feeCalculateMethod}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-feeValue"
                        >
                          Fee amount
                        </label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            className="form-control-alternative"
                            id="input-feeValue"
                            placeholder="Fee Amount"
                            type="number"
                            name="feeValue"
                            required
                            step={values.feeCalculateMethod === "1" ? 0 : 2}
                            value={values.feeValue}
                            onChange={handleInputChange}
                          />
                          {values?.feeCalculateMethod === "2" && (
                            <InputGroupAddon addonType="append">
                              <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                          )}
                        </InputGroup>

                        {errors.feeValue && (
                          <div className="text-left text-danger">
                            <small>* {errors.feeValue}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <Button
                  type="submit"
                  color="primary"
                  className="mt-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddFspTransactionFees;
