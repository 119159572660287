import React, { useEffect } from "react";
// reactstrap components
import ReactSelect from "react-select";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  checkSpace,
  getUserName,
  postMethod,
} from "../../services/httpServices";
const initialValue = {
  groupName: "",
  posid: 0,
  posSerialNumber: "",
  createdBy: "",
  updatedBy: "",
  posDevice: [],
  isBlock: false,
  isDelete: false,
};
const EditPOSGroup = (props) => {
  const [values, setValues] = React.useState(
    props
      ? {
          ...props.values,
          posDevice: props?.values?.details?.map((i) => ({
            label: i.posSerialNumber,
            value: i.posid,
            ...i,
          })),
        }
      : initialValue
  );
  const [allCards, setAllPosDevices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [allGroups, setAllGroups] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const { addToast } = useToasts();
  useEffect(() => {
    fetchAllDevice();
    fetchAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeState = (obj) => {
    setValues(obj);
  };
  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };
  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };
  const closeModal = () => {
    props.closeModal();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "groupName") {
      if (allGroups.includes(value?.toLowerCase().trim())) {
        const errors = {};
        errors.groupName = "Group name already exists!";
        setErrors(errors);
      } else {
        setErrors({});
      }
    }
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };
  const fetchAllGroups = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/POS/Groups/GetAll", {}, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          // Success
          if (!res.data || !res.data?.result.length) {
            res.data = [];
          }
          setAllGroups(
            res.data?.result
              ?.map((i) => i?.groupName?.toLowerCase())
              .filter((i) => i !== values.groupName?.toLowerCase())
          );
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const deleteNFC = (deleteObj) => {
    const isOld = props.values.details?.find(
      (i) => i.posSerialNumber === deleteObj.label
    );
    if (!isOld) {
      return;
    }
    let errorMsg = "";
    setLoading(true);
    setLoading(true);
    postMethod(
      `/api/POS/Groups/SoftDeleteOrBlock/${
        deleteObj.id
      }?isDelete=${!deleteObj.isDelete}&isBlock=${!deleteObj.isBlock}`,
      {},
      "post"
    )
      .then((res) => {
        setLoading(false);
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            const text = deleteObj.isBlock
              ? "POS Group unblocked successfully"
              : "POS Group blocked successfully";
            notifySuccess(text);
            // fetchGroup(deleteObj.id);
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };
  const fetchAllDevice = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/POS/GetAllDevice", {}, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          // Success
          if (!res.data || !res.data.length) {
            res.data = [];
          }
          setAllPosDevices(
            res.data?.map((i) => ({
              value: i.posId,
              label: `${i?.posSerialNumber}`,
              ...i,
            }))
          );
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };
  const validate = (inputs) => {
    const errors = {};
    if (!inputs.groupName) {
      errors.groupName = "Group name is required";
    }
    if (!inputs.posDevice.length) {
      errors.posDevice = "POS Device is required";
    }
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    const payload = values.posDevice.map((item) => ({
      groupName: values.groupName,
      posid: item.posId,
      posSerialNumber: item.posSerialNumber,
      createdBy: getUserName(),
      updatedBy: getUserName(),
      isBlock: false,
      isDelete: false,
    }));
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/POS/Groups/Add", payload, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess("Saved Successfully");
            closeModal();
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };
  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Edit POS Group</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-groupName"
                        >
                          POS Group Name *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-groupName"
                          placeholder="POS Group Name"
                          type="text"
                          required
                          name="groupName"
                          value={values.groupName}
                          onChange={handleInputChange}
                        />
                        {errors.groupName && (
                          <div className="text-left text-danger">
                            <small>* {errors.groupName}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <Row>
                        <Col lg="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-groupName"
                          >
                            Select POS Device *
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <ReactSelect
                              isMulti
                              isSearchable
                              defaultValue={values.posDevice.map((i) => ({
                                value: i.posId,
                                label: `${i?.posSerialNumber}`,
                                ...i,
                              }))}
                              isDisabled={loading}
                              options={allCards}
                              onChange={(e, f) => {
                                if (f.action === "remove-value") {
                                  deleteNFC(f?.removedValue);
                                }
                                if (f.action === "clear") {
                                  f.removedValues.forEach((i) => {
                                    deleteNFC(i);
                                  });
                                }
                                setValues({ ...values, posDevice: e });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          {errors.posDevice && (
                            <div className="text-left text-danger">
                              <small>* {errors.posDevice}</small>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Button
                  type="submit"
                  color="primary"
                  className="mt-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default EditPOSGroup;
